import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, switchMap } from 'rxjs/operators';

import { CommonUIService } from '../../common/common-ui.service';

import { DxDataGridComponent } from 'devextreme-angular';

import DataGrid from "devextreme/ui/data_grid";

export enum DataRowStatus {
    Normal,
    Inserting,
    Editing,
    Deleting
}

export interface IHotelCode {
    HOTEL_CODE: string;
    HOTEL_NAME: string;
}

export interface IRfqHotelCode {
    RFQ_HOTEL_CODE: string;
    RFQ_HOTEL_NAME: string;
}

export interface IBizareaCode {
    BIZAREA_CODE: string;
    BIZAREA_NAME: string;
}

export interface IDeptCd {
    HOTEL_CODE: string;
    DEPT_CD: string;
    DPKNAME: string;
}

export interface IOutletCode {
    OUTLET_CODE: string;
    OUTLET_NAME: string;
}

export interface IMemuLar {
    MENUID_LAR: string;
    MENUNM_LAR: string;
}

export interface IMemuMid {
    MENUID_MID: string;
    MENUNM_MID: string;
}

export interface ICmmCodeValue {
    CODE: string;
    CODE_NAME: string;
}

export interface IPeriodCodes {
    PERIOD_CODE: string;
    PERIOD_NAME: string;
}

export interface IItemLevels {
    ITLEV1: string;
    ITHNAME: string;
}

@Injectable({
    providedIn: 'root',
})
export class CmmService implements Resolve<any> {
    hotelCodes: IHotelCode[];
    bizareaCodes: IBizareaCode[];
    deptCds: IDeptCd[];
    outletCodes: IOutletCode[];
    menuLars: IMemuLar[];
    menuMids: IMemuMid[];
    cmmCodeValues: ICmmCodeValue[];
    periodCodes: IPeriodCodes[];
    itemLevels: IItemLevels[];
    rfqHotelCodes: IRfqHotelCode[];

    onHotelCodeChanged: BehaviorSubject<any>;
    onBizareaCodeChanged: BehaviorSubject<any>;
    onOutletCodeChanged: BehaviorSubject<any>;
    onMenuLarChanged: BehaviorSubject<any>;
    onMenuMidChanged: BehaviorSubject<any>;
    onCmmCodeValueChanged: BehaviorSubject<any>;
    onDeptCdChanged: BehaviorSubject<any>;
    onRfqHotelCodeChanged: BehaviorSubject<any>;

    searchFormSelectedValue: {
        HOTEL_CODE: string;
        BIZAREA_CODE: string;
        OUTLET_CODE: string;
        MENUID_LAR: string;
        MENUID_MID: string;
        DEPT_CD: string;
        RFQ_HOTEL_CODE: string;
    } = {
            HOTEL_CODE: '',
            BIZAREA_CODE: '',
            OUTLET_CODE: '',
            MENUID_LAR: '',
            MENUID_MID: '',
            DEPT_CD: '',
            RFQ_HOTEL_CODE: ''
        };

    constructor(
        private httpClient: HttpClient,
        private ui: CommonUIService
    ) {
        this.onHotelCodeChanged = new BehaviorSubject([]);
        this.onBizareaCodeChanged = new BehaviorSubject([]);
        this.onOutletCodeChanged = new BehaviorSubject([]);
        this.onMenuLarChanged = new BehaviorSubject([]);
        this.onMenuMidChanged = new BehaviorSubject([]);
        this.onCmmCodeValueChanged = new BehaviorSubject([]);
        this.onDeptCdChanged = new BehaviorSubject([]);
        this.onRfqHotelCodeChanged = new BehaviorSubject([]);
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getHotelCode(),
                this.getOutletCode(),
                this.getMenuLar(),
                this.getMenuMid(),
                this.getDeptCd({ HOTEL_CODE: '', BIZAREA_CODE: '' }),
                this.getRfqHotelCode()
            ]).then(
                ([hotelCodeData,
                    outletCodeData
                ]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * get sysdate
     */
    async getToDay(): Promise<any> {
        return await new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getToDay`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    resolve(rows[0].TO_DAY);
                }, reject);
        });
    }

    /**
     * dbConfig
     */
    dbConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient.post<any>(`${environment.baseUrl}/v1/cmm/dbConfig`, {}).subscribe(
                result => {
                    resolve(result);
                },
                err => reject(err)
            );
        });
    }

    /**
     * accesslog
     */
    accessLog(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient.post<any>(`${environment.baseUrl}/v1/cmm/accessLog`, params).subscribe(
                result => {
                    resolve(result);
                },
                err => reject(err)
            );
        });
    }

    /**
     * 호텔코드 조회
     */
    async getHotelCodes(): Promise<any> {
        return await this.getHotelCode();
    }
    private getHotelCode(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/hotel-code`, {})
                // .post<any>(`${environment.baseUrl}/v1/cmm/auth-hotel-code`, {}) // 권한 체크하는걸로 변경해야함
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.hotelCodes = rows;
                    this.onHotelCodeChanged.next(rows);
                    if(this.hotelCodes.length > 0){
                        this.searchFormSelectedValue.HOTEL_CODE = this.hotelCodes[0].HOTEL_CODE;
                    }
                    resolve(rows);
                }, reject);
        });
    }

    /**
     * 견적요청호텔코드 조회
     */
    async getRfqHotelCodes(): Promise<any> {
        return await this.getRfqHotelCode();
    }
    private getRfqHotelCode(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/rfq-hotel-code`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.rfqHotelCodes = rows;
                    this.onRfqHotelCodeChanged.next(rows);
                    if(this.rfqHotelCodes.length > 0){
                        this.searchFormSelectedValue.RFQ_HOTEL_CODE = this.rfqHotelCodes[0].RFQ_HOTEL_CODE;
                    }
                    resolve(rows);
                }, reject);
        });
    }

    /**
     * 권한 있는 호텔코드 조회
     */
    async getAuthHotelCodes(): Promise<any> {
        return await this.getAuthHotelCode();
    }
    private getAuthHotelCode(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/auth-hotel-code`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.hotelCodes = rows;
                    this.onHotelCodeChanged.next(rows);
                    if(this.hotelCodes.length > 0){
                        this.searchFormSelectedValue.HOTEL_CODE = this.hotelCodes[0].HOTEL_CODE;
                    }
                    resolve(rows);
                }, reject);
        });
    }

    /**
     * 사업영역 조회
     */
    async getBizareaCodes(param): Promise<any> {
        return await this.getBizareaCode(param);
    }
    private getBizareaCode(param): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/bizarea-code`, param)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.bizareaCodes = rows;
                    this.onBizareaCodeChanged.next(rows);
                    if (this.bizareaCodes.length > 0) {
                        this.searchFormSelectedValue.BIZAREA_CODE = this.bizareaCodes[0].BIZAREA_CODE;
                    } else {
                        this.searchFormSelectedValue.BIZAREA_CODE = '';
                    }
                    resolve(rows);
                }, reject);
        });
    }
    /**
     * 부서 조회
     */
    async getDeptCds(param?): Promise<any> {
        return await this.getDeptCd(param);
    }
    private getDeptCd(param?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/dept-cd`, param)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.deptCds = rows;
                    this.onDeptCdChanged.next(rows);
                    if (this.deptCds.length > 0) {
                        this.searchFormSelectedValue.DEPT_CD = this.deptCds[0].DEPT_CD;
                    } else {
                        this.searchFormSelectedValue.DEPT_CD = '';
                    }
                    resolve(rows);
                }, reject);
        });
    }

    /**
     * 부서 조회 (멀티호텔)
     */
    async getDeptCdsHT(param?): Promise<any> {
        return await this.getDeptCdHT(param);
    }
    private getDeptCdHT(param?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/dept-cd-ht`, param)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.deptCds = rows;
                    this.onDeptCdChanged.next(rows);
                    if (this.deptCds.length > 0) {
                        this.searchFormSelectedValue.DEPT_CD = this.deptCds[0].DEPT_CD;
                    } else {
                        this.searchFormSelectedValue.DEPT_CD = '';
                    }
                    resolve(rows);
                }, reject);
        });
    }

    /**
     * TMS 영업장코드 조회
     */
    async getOutletCodes(): Promise<any> {
        return await this.getOutletCode();
    }
    private getOutletCode(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/outlet-code`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.outletCodes = rows;
                    this.onOutletCodeChanged.next(rows);
                    //this.searchFormSelectedValue.OUTLET_CODE = this.outletCodes[0].OUTLET_CODE;
                    resolve();
                }, reject);
        });
    }

    /**
     * TMS영업장코드 조회
     * @param hotelCode 호텔코드
     */
    getOutletCodeByHotelCode(hotelCode: any): Promise<IOutletCode | any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get<any>(
                    `${environment.baseUrl}/v1/tms/m2-3111/m3-w5742/outlet-code/${hotelCode}`
                )
                .subscribe(result => {
                    resolve(result.rows);
                });
        });
    }

    /**
     * 사용자 기준으로 필터링할 아울렛 코드 가져오기
     * @param payload 
     */
    getOutletCodesByUserId(): Promise<IOutletCode | any> {
        //console.log('invoked getOutletCodesByUserId')
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(
                    `${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/outlet-codes-by-user`, {}
                )
                .subscribe(result => {
                    //console.log('result getOutletCodesByUserId', result);
                    resolve(result);
                });
        });
    }

    /**
     * 구매업무용 업장코드 조회
     */
    getOutletCodesForPurchase(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl }/v1/cmm/outlet-codes-for-purchase`, {})
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }

    /**
     * 영업별 자재 매출 매출 그룹
     */
    getProdClassCodeForPurchase(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl }/v1/cmm/prod-class-code-for-purchase`, {})
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }

    /**
     * 공통코드 조회
     * @param data CODE1+CODE2
     */
    async getCmmCodeValues(data: any): Promise<any> {
        return await this.getCmmCodeValue({ CODE_VALUE: data });
    }
    private getCmmCodeValue(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/cmmCodeValue`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.cmmCodeValues = rows;
                    resolve(this.cmmCodeValues);
                }, reject);
        });
    }

    /**
     * 공통코드 조회
     * @param params 
     */
    retrieveCommonCode(params: { HOTEL_CODE: string, CODE1: string, CODE2: string }): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/retrieve-common-code`, params)
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }

    private getMenuLar(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/menu-lar`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.menuLars = rows;
                    this.onMenuLarChanged.next(rows);
                    // this.searchFormSelectedValue.MENUID_LAR = this.menuLars[0].MENUID_LAR;
                    resolve();
                }, reject);
        });
    }

    private getMenuMid(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/menu-mid`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.menuMids = rows;
                    this.onMenuMidChanged.next(rows);
                    // this.searchFormSelectedValue.MENUID_MID = this.menuMids[0].MENUID_MID;
                    resolve();
                }, reject);
        });
    }

    getPeriodCodes(payload: { HOTEL_CODE, OUTLET_CODE }): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w5742/retrieve`, payload)
                .pipe(
                    switchMap(res => {
                        return of(
                            res.rows.map(row =>
                                Object.assign(row, { USE_YN: row.USE_YN === 'Y' ? true : false })
                            )
                        );
                    })
                )
                .subscribe(
                    rows => {
                        this.periodCodes = rows;
                        resolve(this.periodCodes);
                    },
                    err => reject(err)
                );
        });
    }

    getHostIp(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient.post<any>(`${environment.baseUrl}/v1/cmm/hostIp`, {}).subscribe(
                result => {
                    resolve(result.hostIp);
                },
                err => reject(err)
            );
        });
    }

    /**
     * 재자그룹 조회(MA_BITLEV)
     * @param data 
     */
    async getItemLevels(): Promise<any> {
        return await this.getItemLevel();
    }
    private getItemLevel(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getItemLevel`, {})
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }

    /**
     * 재자그룹 LEVEL2 조회(MA_BITLEV)
     * @param data 
     */
    async getItemLevels2(data: any): Promise<any> {
        return await this.getItemLevel2(data);
    }
    private getItemLevel2(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getItemLevel2`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }

    /**
     * 재자그룹 LEVEL2_1 조회(MA_BITLEV)
     * @param data 
     */
    async getItemLevels2_1(data: any): Promise<any> {
        return await this.getItemLevel2_1(data);
    }
    private getItemLevel2_1(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getItemLevel2_1`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }
    
    /**
     * 재자그룹 LEVEL3 조회(MA_BITLEV)
     * @param data 
     */
    async getItemLevels3(data: any): Promise<any> {
        return await this.getItemLevel3(data);
    }
    private getItemLevel3(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getItemLevel3`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }

    /**
     * 아웃렛 조회(MA_OUTLET)
     * @param data 
     */
    async getOutlet(data?: any): Promise<any> {
        return await this.getOutlets(data);
    }
    private getOutlets(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getOutlets`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }

    /**
     * 아웃렛 조회(MA_OUTLET with Code)
     * @param data 
     */
    async getOutletList(data?: any): Promise<any> {
        return await this.getOutletsWithCD(data);
    }
    private getOutletsWithCD(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getOutletsWithCD`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }
    
        
    /**
     * Menu 조회(MA_RCPHD)
     * @param data 
     */
    async getMenu(data?: any): Promise<any> {
        return await this.getMenuList(data);
    }
    private getMenuList(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/getMenuList`, data)
                .pipe(
                    map((data: any) => {
                        return data.rows;
                    })
                )
                .subscribe(rows => {
                    this.itemLevels = rows;
                    resolve(this.itemLevels);
                }, reject);
        });
    }


    /**
     * 인자로 받은 x, y가 서로 같은지를 비교
     * @param x 
     * @param y 
     */
    public deepEquals(x: any, y: any): boolean {
        if (x === y) {
            return true; // if both x and y are null or undefined and exactly the same
        } else if (!(x instanceof Object) || !(y instanceof Object)) {
            return false; // if they are not strictly equal, they both need to be Objects
        } else if (x.constructor !== y.constructor) {
            // they must have the exact same prototype chain, the closest we can do is
            // test their constructor.
            return false;
        } else {
            for (const p in x) {
                if (!x.hasOwnProperty(p)) {
                    continue; // other properties were tested using x.constructor === y.constructor
                }
                if (!y.hasOwnProperty(p)) {
                    return false; // allows to compare x[ p ] and y[ p ] when set to undefined
                }
                if (x[p] === y[p]) {
                    continue; // if they have the same strict value or identity then they are equal
                }
                if (typeof (x[p]) !== 'object') {
                    return false; // Numbers, Strings, Functions, Booleans must be strictly equal
                }
                if (!this.deepEquals(x[p], y[p])) {
                    return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                    return false;
                }
            }
            return true;
        }
    }

    /**
     * 인자로 넘겨준 grid의 편집(입력, 수정, 삭제)된 데이터를 Get
     * @param grid 
     */
    public getEditingDatas(grid: DxDataGridComponent): Promise<any> {
        if (!grid) {
            return Promise.reject({ details: 'grid 인자값이 정의되어 있지 않습니다.' });
        } else {
            return new Promise((resolve, reject) => {
                let invalidDatas = [];
                let editDatas = [];

                const rowValidatingEventHandler = (e: any) => {
                    if (e.isValid === false) {
                        invalidDatas.push(e);
                    }
                }
                const rowInsertingEventHandler = (e: any) => {
                    editDatas.push({
                        mode: 'INSERTING',
                        data: Object.assign({}, e.data)
                    });

                    e.cancel = true;
                }
                const rowUpdatingEventHandler = (e: any) => {
                    editDatas.push({
                        mode: 'UPDATING',
                        key: Object.assign({}, e.key),
                        newData: Object.assign({}, e.newData),
                        oldData: Object.assign({}, e.oldData)
                    });

                    e.cancel = true;
                }
                const rowRemovingEventHandler = (e: any) => {
                    editDatas.push({
                        mode: 'REMOVING',
                        key: e.key
                    });

                    e.cancel = true;
                }

                grid.instance.on('rowValidating', rowValidatingEventHandler);
                grid.instance.on('rowInserting', rowInsertingEventHandler);
                grid.instance.on('rowUpdating', rowUpdatingEventHandler);
                grid.instance.on('rowRemoving', rowRemovingEventHandler);

                grid.instance.saveEditData()
                    .then(() => {
                        grid.instance.off('rowValidating', rowValidatingEventHandler);
                        grid.instance.off('rowInserting', rowInsertingEventHandler);
                        grid.instance.off('rowUpdating', rowUpdatingEventHandler);
                        grid.instance.off('rowRemoving', rowRemovingEventHandler);

                        resolve({
                            invalidDatas: invalidDatas,
                            editDatas: editDatas
                        });
                    })
                    .catch(error => reject(error));
            })
        }
    }

    // 전자결재 정보 INSERT
    insertAppInfo(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/insertAppInfo`, data)
                .subscribe(
                    result => {
                        result.rowsAffected ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        return reject(err.message);
                    }
                );
        });
    }

    // 전자결재 P.O Market List
    insertMarketList(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/insertMarketList`, data)
                .subscribe(
                    result => {
                        result.rowsAffected ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        return reject(err.message);
                    }
                );
        });
    }

    // 전자결재 Receiving List
    insertReceivingList(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/insertReceivingList`, data)
                .subscribe(
                    result => {
                        // result.rowsAffected ? resolve(result) : reject(result.errorText);
                        result ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        return reject(err.message);
                    }
                );
        });
    }

    /**
     * 전자결재 중복체크
     */
    getAppInfoDuplCheck(key:any,data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl }/v1/cmm/getAppInfoDuplCheck`, {key,data})
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }
    // 전자결재 Issu List
    insertIssuList(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/cmm/insertIssuList`, data)
                .subscribe(
                    result => {
                        // result.rowsAffected ? resolve(result) : reject(result.errorText);
                        result ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        return reject(err.message);
                    }
                );
        });
    }

    /** 품목조회 */
    getItemList(params?: any): Observable<any> {
        return this.httpClient
          .post<any>(`${ environment.baseUrl}/v1/cmm/itemList`, params)
          .pipe(
            map(data => {
              return data.rows;
            })
          );
    }

    /** 품목조회(품목코드 없는것 제외 9로 시작하는 품목) */
    getItemList2(params?: any): Observable<any> {
        return this.httpClient
          .post<any>(`${ environment.baseUrl}/v1/cmm/itemList2`, params)
          .pipe(
            map(data => {
              return data.rows;
            })
          );
    }

    /** 품목그룹조회 */
    getItemLevelList(params?: any): Observable<any> {
        return this.httpClient
          .post<any>(`${ environment.baseUrl}/v1/cmm/getItemLevel`, params)
          .pipe(
            map(data => {
              return data.rows;
            })
          );
    }   

    /** 거래처조회 */
    getVendorList(params?: any): Observable<any> {
        return this.httpClient
          .post<any>(`${ environment.baseUrl}/v1/cmm/getVendorList`, params)
          .pipe(
            map(data => {
              return data.rows;
            })
          );
    }

    /**
     * 예산, 내부오더 조회
     */
    getAvailableBudget(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/cmm/retrieve-available-budget`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    
    /** 품목그룹조회 */
    getTaxCDList(params?: any): Observable<any> {
        return this.httpClient
          .post<any>(`${ environment.baseUrl}/v1/cmm/getTaxCDList`, params)
          .pipe(
            map(data => {
              return data.rows;
            })
          );
    }   

    /** 리포트 키 생성 */
    getReportKey(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/cmm/getReportKey`, params)
                .subscribe( 
                    //res => resolve(res),
                    //err => reject(err)
                    
                    result => {
                        result.rowsAffected ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        return reject(err.message);
                    }
                );
        });
    }

    /** 사용량 */
    getReceivingQty(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl }/v1/cmm/retrieve-receiving-qty`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    /** 입고데이타 */
    getReceivingPredata(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl }/v1/cmm/retrieve-receiving-predata`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

}
